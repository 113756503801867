/**
 * @author Sergey Tumarkin https://tumarkin.me
 */
import { 
    Alert,
    Badge,
    Accordion,
    Spinner, 
    Form,
    FloatingLabel,
    Button,
    DropdownButton,
    Dropdown,
    ButtonGroup,
    Modal
} from 'react-bootstrap';
import * as API from '../data/API';
import { useState, useEffect, useCallback, useRef } from 'react';
import SortableList, { SortableItem,  SortableKnob} from "react-easy-sort";
import * as Config from '../data/Config';
import axios from 'axios';
import { useBlocker } from "react-router-dom";


export default function SettingsForm ( props ){
    const {authtoken} = props

    const [isLoading, setIsLoading] = useState(false)
    const [isUpdateTemplateSuccess, setIsUpdateTemplateSuccess] = useState(false)

    const [indexTemplateActive, setIndexTemplateActive] = useState(0)
    const [indexTemplatePrevious, setIndexTemplatePrevious] = useState(0)

    const [activePlan, setActivePlan] = useState(null)
    
    const [templates, setTemplates] = useState([])
    const [source, setSource] = useState(null)
    const [count, setCount] = useState(0);
    const [unsavedChanges, setUnsavedChanges] = useState(false);

    const [showModal, setShowModal] = useState(false);
    const [checkNameInput, setCheckNameInput] = useState('');
    const [checkReportInput, setCheckReportInput] = useState('');
    const [checkClauseInput, setCheckClauseInput] = useState('');
    const [checkValueCheckbox, setCheckValueCheckbox] = useState(true);
    const [checkModal, setCheckModal] = useState({});
    
    const handleCloseModal = () => setShowModal(false);
    const handleShowModal = () => setShowModal(true);

    useEffect(() => {
        getTemplates()
        
        API.Get({ method:'getaccountplan', authtoken})
        .then( ({data}) => {
            const {result, plan} = data
            if (result){
                setActivePlan( plan)
            }
        })
    }, [])

    useEffect(() => {
        setUnsavedChanges(count>0)
    }, [count])

    const getTemplates = () => {
        setIsLoading(true)
        API.Get({ method:'gettemplates', authtoken})
        .then(({data}) => {
            const {result, templates} = data
            if ( result && templates ){
                setTemplates(templates)
                const indexTemplateActive = templates.findIndex( template => template.is_active!=0 )
                const activeTemplate = templates.find( template => template.is_active!=0 )

                axios({method: 'get',baseURL: Config.Domain + activeTemplate.source_path})
                .then( ({data}) => setSource(data))


                setIndexTemplateActive( indexTemplateActive )
                setIndexTemplatePrevious( templates.findIndex( (template, i) => template.is_active!=0 && i!=indexTemplateActive ) )
            }
        })
        .finally(()=>setIsLoading(false))
    }


    const updateTemplate = () => {
        setIsUpdateTemplateSuccess(false)
        setIsLoading(true)

        const dictionary = {}
        const form = {
            id: '',
            token: '',
            authtoken: '',
            address: '',
            formTemplateVersion: "{formTemplateVersion}",
            apartment: [],
            nested_templates:[]
        }
        source.rooms.forEach( room => {
            const {id, name, type, comment, nested, defaultNested0, defaultNested1, defaultNested2} = room
            dictionary[id] = {name}
            form.nested_templates.push( {id, type, comment, nested, defaultNested0, defaultNested1, defaultNested2} )
        })
        source.checks.forEach(({id,name,report,clause,nested,type,value}) => {
            dictionary[id] = {name,report,clause}
            if (nested){
                nested.forEach(({id,name,report,clause}) => dictionary[id] = {name,report,clause})
            }
            form.nested_templates.push( {id,type,value,nested: nested.map(({id,type,value})=>({id,type,value}))} )
        })

        API.Post({ method:'updatetemplate', authtoken}, {
            source: JSON.stringify(source), 
            dictionary: JSON.stringify(dictionary), 
            form: JSON.stringify(form)
        })
        .then(({data}) => {
            console.log(data);
            if ( data.result ){
                setIsUpdateTemplateSuccess(true)
                setCount(0)
                getTemplates()
            }
        })
        .finally(()=>setIsLoading(false))
    }
 
    const reverseTemplate = (deactivate_id) => {
        setIsUpdateTemplateSuccess(false)
        setIsLoading(true)
        API.Post({ method:'reversetemplate', authtoken},{deactivate_id})
        .then(res => {
            console.log(res.data)
            if ( res.data.result ){
                getTemplates()
            }
        })
        .finally(()=>setIsLoading(false))
    }

    const getHumanIndex = ( templates, index ) => templates.length - index

    function arrayMoveMutable(array, fromIndex, toIndex) {
        const startIndex = fromIndex < 0 ? array.length + fromIndex : fromIndex;
    
        if (startIndex >= 0 && startIndex < array.length) {
            const endIndex = toIndex < 0 ? array.length + toIndex : toIndex;
    
            const [item] = array.splice(fromIndex, 1);
            array.splice(endIndex, 0, item);
        }
    }
    
    let sortingArray = []

    const onSortEnd = (oldIndex, newIndex) => {
        arrayMoveMutable(sortingArray, oldIndex, newIndex);
        setSource(source);
        setCount(count+1);
    };


    const renderRooms = ( arr ) => (
        <>
            <div className="d-flex justify-content-between">
                <div className="h2">Помещения</div>
                <Button 
                    variant='outline-primary'
                    className='ms-3 my-2'
                    onClick={()=>{
                        let name = window.prompt("Название помещения")
                        if (name){
                            arr.unshift({ id: Date.now().toString(), name, type:'room', comment:'', nested: [], defaultNested0:[], defaultNested1:[], defaultNested2:[] })
                            setSource(source)
                            setCount(count+1)
                        }
                    }}
                >
                    Добавить помещение
                </Button>
            </div>

            <Accordion defaultActiveKey="0">
                <SortableList
                    onSortEnd={(oldIndex, newIndex)=>{
                        sortingArray = arr
                        onSortEnd(oldIndex, newIndex)
                    }}
                    className="list"
                    draggedItemClassName="dragged"
                >
                {arr.map( (item, i) => (
                    <SortableItem id={item.id}>
                        <Accordion.Item eventKey={item.id}>
                            <Accordion.Header>
                                <SortableKnob><span style={{cursor: 'grab'}}>⋮⋮</span></SortableKnob>&nbsp;&nbsp;
                                {item.name}
                            </Accordion.Header>
                            <Accordion.Body className='mb-4'>
                            <div className="d-flex justify-content-end">
                                    <div>
                                        <Button 
                                            variant='outline-secondary'
                                            className='mx-3 mb-3'
                                            size='sm'
                                            onClick={()=>{
                                                let name = window.prompt("Укажите название набора", item.name)
                                                if (name){
                                                    item.name = name
                                                    setSource(source)
                                                    setCount(count+1)
                                                }
                                            }}
                                        >
                                            Переименовать помещение
                                        </Button>
                                        <Button 
                                            variant='outline-danger'
                                            className='mb-3'
                                            size='sm'
                                            onClick={()=>{
                                                if (window.confirm('Точно удаляем?')) {
                                                    arr.splice(i, 1)
                                                    setSource(source)
                                                    setCount(count+1)
                                                } 
                                            }}
                                        >
                                            Удалить помещение
                                        </Button>
                                    </div>
                                </div>
                                    <div className='small'>
                                        Наборы проверок добавляемые по умолчанию в это помещение для объектов разными типами отделки:
                                    </div>
                                { ( item.defaultNested0 ? renderRoomNested( item.defaultNested0, 'Без отделки' ) : null )}
                                { ( item.defaultNested1 ? renderRoomNested( item.defaultNested1, 'White-box' ) : null )}
                                { ( item.defaultNested2 ? renderRoomNested( item.defaultNested2, 'C отделкой' ) : null )}
                            </Accordion.Body>
                        </Accordion.Item>
                    </SortableItem>
                ))}
                </SortableList>
                
            </Accordion>
        </>
    )

    const renderRoomNested = ( arr, title='' ) => (
        <Alert variant="secondary" className='mt-3'> 
            <div className="small">{title}</div>
            <SortableList
                onSortEnd={(oldIndex, newIndex)=>{
                    sortingArray = arr
                    onSortEnd(oldIndex, newIndex)
                }}
                className="list"
                draggedItemClassName="dragged"
            >
                {arr.map( (item, i) => (
                    <SortableItem id={item.id}>
                        <Badge bg="secondary" className='m-1 p-2'>
                            <SortableKnob><span style={{cursor: 'grab'}}>⋮⋮</span></SortableKnob>&nbsp;&nbsp; 
                            {source.checks.find(({id})=>id==item).name} &nbsp;&nbsp; 
                            <Form.Check
                                inline
                                type='checkbox'
                                className='mx-1'
                                checked={true}
                                onChange={()=>{
                                    arr.splice(i, 1)
                                    setSource(source)
                                    setCount(count+1)
                                }}
                            />
                        </Badge>
                    </SortableItem>
                ))}
            </SortableList>
            
            {source.checks.filter(({id})=>!arr.includes(id)).map( (item) => (
                <Badge bg='light' text="dark" className='m-1 p-2'>
                    {item.name}&nbsp;&nbsp; 
                    <Form.Check
                        inline
                        type='checkbox'
                        className='mx-1'
                        checked={false}
                        onChange={()=>{
                            console.log(arr)
                            arr.push(item.id)
                            setSource(source)
                            setCount(count+1)
                        }}
                    />
                </Badge>
            ))
            }
        </Alert>
)

    
    const renderCheckTypes = ( arr ) => (
        <>
            <div className="d-flex justify-content-between">
                <div className="h2">Проверки</div>
                <Button 
                    variant='outline-primary'
                    className='ms-3 my-2'
                    onClick={()=>{
                        const name = window.prompt("Название набора")
                        if (name){
                            arr.unshift({ id: Date.now().toString(), name, report:'', type: 'section', nested:[] })
                            setSource(source)
                            setCount(count+1)
                        }
                    }}
                >
                    Добавить набор проверок
                </Button>
            </div>

            <Accordion defaultActiveKey="0">
                <SortableList
                    onSortEnd={(oldIndex, newIndex)=>{
                        sortingArray = arr
                        onSortEnd(oldIndex, newIndex)
                    }}
                    className="list"
                    draggedItemClassName="dragged"
                >
                {arr.map( (item, i, arr) => (
                    <SortableItem id={item.id}>
                        <Accordion.Item eventKey={item.id}>
                            <Accordion.Header>
                                <SortableKnob><span style={{cursor: 'grab'}}>⋮⋮</span></SortableKnob>&nbsp;&nbsp;
                                {item.name}
                            </Accordion.Header>
                            <Accordion.Body>
                                <div className="d-flex justify-content-between">
                                    <div>
                                        <Button 
                                            variant='outline-primary'
                                            className='ms-3 mb-3'
                                            size='sm'
                                            onClick={()=>{
                                                // let arrStrings = [
                                                //     "Монолитное перекрытие. Перепад уровня",
                                                //     "Монолитное перекрытие. Локальные неровности",
                                                //     "Монолитное перекрытие. Наплывы бетона",
                                                //     "Монолитное перекрытие. Сквозное отверстие",
                                                //     "Монолитное перекрытие. Трещина",
                                                //     "Монолитное перекрытие. Повреждения/Разрушение ",
                                                //     "Монолитное перекрытие. Выпуски арматуры ",
                                                //     "Монолитное перекрытие. Иное",
                                                // ]
                                                // let i=0
                                                // arrStrings.reverse().forEach(function(name) {
                                                //     i++
                                                //     if (name){
                                                //         item.nested.unshift({ id: Date.now().toString().slice(-6)+'0'+i, name, report:name, clause:'', type: 'check', value: true })
                                                //     }
                                                // });
                                                // setSource(source)
                                                // setCount(count+1)
                                                let name = window.prompt("Название проверки")
                                                if (name){
                                                    item.nested.unshift({ id: Date.now().toString(), name, report:'', clause:'', type: 'check', value: true })
                                                    setSource(source)
                                                    setCount(count+1)
                                                }
                                            }}
                                        >
                                            + Добавить проверку
                                        </Button>

                                    </div>
                                    <div>
                                        <DropdownButton 
                                            as={ButtonGroup}
                                            className='mx-3 mb-3'
                                            size='sm' 
                                            variant='outline-secondary' 
                                            title="Переименовать" 
                                            id="bg-nested-dropdown"
                                        >
                                            <Dropdown.Item 
                                                eventKey="1"
                                                onClick={()=>{
                                                    let name = window.prompt("Укажите название набора", item.name)
                                                    if (name){
                                                        item.name = name
                                                        setSource(source)
                                                        setCount(count+1)
                                                    }
                                                }}
                                            >
                                                Наименивание в приложении
                                            </Dropdown.Item>
                                            <Dropdown.Item 
                                                eventKey="2"
                                                onClick={()=>{
                                                    let report = window.prompt("Укажите название набора для отчетов", item.report || item.name)
                                                    if (report){
                                                        item.report = report
                                                        setSource(source)
                                                        setCount(count+1)
                                                    }
                                                }}
                                            >
                                                Наименивание в отчете
                                                
                                            </Dropdown.Item>
                                        </DropdownButton>
                                        <Button 
                                            variant='outline-danger'
                                            className='mb-3'
                                            size='sm'
                                            onClick={()=>{
                                                if (window.confirm('Точно удаляем?')) {
                                                    source.rooms.forEach( room => {
                                                        const { defaultNested0, defaultNested1, defaultNested2 } = room
                                                        defaultNested0.splice( defaultNested0.findIndex(i => i==item.id), 1)
                                                        defaultNested1.splice( defaultNested1.findIndex(i => i==item.id), 1)
                                                        defaultNested2.splice( defaultNested2.findIndex(i => i==item.id), 1)
                                                    })
                                                    arr.splice(i, 1)
                                                    setSource(source)
                                                    setCount(count+1)
                                                } 
                                            }}
                                        >
                                            Удалить набор
                                        </Button>
                                    </div>
                                </div>
                            { ( item.nested ? renderChecks( item.nested ) : null )}
                            </Accordion.Body>
                        </Accordion.Item>
                    </SortableItem>
                ))}
                </SortableList>
            </Accordion>
        </>
    )

    const renderChecks = ( arr ) => (
            <SortableList
                onSortEnd={(oldIndex, newIndex)=>{
                    sortingArray = arr
                    onSortEnd(oldIndex, newIndex)
                }}
                className="list"
                draggedItemClassName="dragged"
            >
            {arr.map( (item, i, arr) => (
                <SortableItem id={item.id}>
                    <Alert 
                        id={item.id} 
                        variant='light' 
                        className='ms-3 d-flex justify-content-between'
                    >
                        <div onClick={ ()=>{
                            console.log(item);
                            setCheckModal(item)
                            setCheckNameInput(item.name)
                            setCheckReportInput(item.report)
                            setCheckClauseInput(item.clause)
                            setCheckValueCheckbox(item.value)
                            handleShowModal()
                        }}>
                            <SortableKnob><span style={{cursor: 'grab'}}>⋮⋮</span></SortableKnob>&nbsp;&nbsp;
                            <span>
                                { item.name.length > 0 ? item.name : (<i>Название проверки</i>) }   
                            </span> 
                            {
                                !item.value && 
                                <div className="small ms-4">
                                    <Form.Check // prettier-ignore
                                        id='value'
                                        type='switch'
                                        label={`по умолчанию`}
                                        checked={!item.value}
                                        isInvalid={!item.value}
                                        className=''
                                        disabled
                                    />  
                                </div>
                            }
                            <div className="small ms-4">
                                <div className="small">
                                    { item.report.length > 0 ? item.report : (<i style={{backgroundColor:'beige'}} className='p-1'>Заполните: Текст недостатка в отчете для данной проверки</i>) }    
                                </div>
                            </div>
                            <div className="small ms-4">
                                <div className="small">
                                    { item.clause.length > 0 ? item.clause : (<i style={{backgroundColor:'beige'}} className='p-1'>Заполните: Текст для Заключения: нарушения каких нормативных актов допущены</i>) }    
                                </div>
                            </div>
                        </div>
                        <div>
                                <Button 
                                    variant='link'
                                    style={{color: 'lightgray'}}
                                    onClick={()=>{
                                        setCheckModal(item)
                                        setCheckNameInput(item.name)
                                        setCheckReportInput(item.report)
                                        setCheckClauseInput(item.clause)
                                        setCheckValueCheckbox(item.value)
                                        handleShowModal()
                                    }}
                                >
                                    <i className="fas fa-pen-to-square"></i>
                                </Button>
                                <Button 
                                variant='link'
                                style={{color: 'lightgray'}}
                                onClick={()=>{
                                    if (window.confirm('Точно удаляем?')) {
                                        arr.splice(i, 1)
                                        setSource(source)
                                        setCount(count+1)
                                    } 
                                }}
                            >
                                <i className="fas fa-trash-can"></i>
                            </Button>
                            

                        </div>
                        
                    </Alert>
                </SortableItem>
            ))}
            </SortableList>
    )



    return (
        <>
            <h1>Настройка приёмки</h1>

            {
                unsavedChanges && 
                <Alert variant="warning" className='mb-3'>
                    ⚠️ Сохраните изменения перед выходом со страницы
                </Alert>
            }

            <div className="my-5"></div>
            { source && renderRooms( source?.rooms ) }
            
            <div className="my-5"></div>
            { source && renderCheckTypes( source?.checks ) }

            {
                isUpdateTemplateSuccess ? (
                    <Alert variant="success" className='mt-3' style={{maxWidth:800}}>
                        Данные сохранены.<br/>Перезапустите мобильные приложения для получения обновлений.
                    </Alert>
                ):null
            }

            <div className="d-flex justify-content-start py-3">
                <Button
                    disabled={isLoading || count==0}
                    onClick={updateTemplate}
                    className='mb-3 px-5'
                >
                    {
                        !isLoading ? (
                            "Сохранить изменения"
                        ):(
                            <Spinner animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        ) 
                    }
                </Button>
                <div className='m-2'>
                    После сохранения изменений они будут сразу применены в мобильных приложениях.
                </div>
            </div>

            <Modal
                show={showModal}
                onHide={handleCloseModal}
                backdrop="static"
                keyboard={false}
                size="lg"
                centered
            >
                <Modal.Header closeButton  className='bg-light'>
                    <Modal.Title>Проверка</Modal.Title>
                </Modal.Header>
                <Modal.Body className='bg-light'>
                    <FloatingLabel label="Короткое название проверки (отображается в приложении)" className='mb-5'>
                        <Form.Control 
                            id="name"
                            type="text" 
                            value={checkNameInput} 
                            onChange={(e)=> setCheckNameInput(e.target.value) }
                        />
                        <Form.Text muted className='d-flex ms-1'>
                            Отображается в приложении. Должно быть коротким и ёмким для быстрого поиска специалистом.
                        </Form.Text>
                    </FloatingLabel>

                    <FloatingLabel label="Полное название проверки (отображается в отчетах)" className='mb-5'>
                        <Form.Control 
                            id="report"
                            type="text" 
                            value={checkReportInput} 
                            onChange={(e)=> setCheckReportInput(e.target.value) }
                            style={{height: 120}}
                            rows={4}
                            as="textarea"
                        />
                        <Form.Text muted className='d-flex ms-1 mb-3'>
                            Отображается в отчетах. Должно быть полным и исчерпывающим для предоставления застройщику или в суд в претензионной документации. Будет доступно к редактированию в каждой конкретной приёмке/проверке.
                        </Form.Text>
                    </FloatingLabel>

                    <FloatingLabel label="Нормативная документация" className='mb-3'>
                        <Form.Control 
                            id="clause"
                            type="text" 
                            value={checkClauseInput} 
                            onChange={(e)=> setCheckClauseInput(e.target.value) }
                        />
                        <Form.Text className='d-flex ms-1 fs-7'>
                            Отображается в Заключении. Укажите какие пункты нормативной документации (ГОСТ, СНиП, ДДУ и т.д.) нарушены при выявлении недостатка в данной проверке
                        </Form.Text>
                    </FloatingLabel>

                    <Form.Check // prettier-ignore
                        id='value'
                        type="switch"
                        label={`Сделать нарушением по умолчанию в каждой новой приёмке`}
                        checked={!checkValueCheckbox}
                        isInvalid={!checkValueCheckbox}
                        onChange={(e)=> setCheckValueCheckbox(!e.target.checked) }
                    />
                </Modal.Body>
                <Modal.Footer className='bg-light'>
                    <Button variant="secondary-outline" onClick={handleCloseModal}>Отмена</Button>
                    <Button variant="primary" onClick={()=>{
                        checkModal.name = checkNameInput
                        checkModal.report = checkReportInput
                        checkModal.clause = checkClauseInput
                        checkModal.value = checkValueCheckbox
                        setCount(count+1)
                        handleCloseModal()
                    }}>Готово</Button>
                </Modal.Footer>
            </Modal>

            {/* {
                templates?.length > 1 ? (
                    <Alert variant="secondary" style={{maxWidth:800}} className='mt-5'>
                        <Alert.Heading>
                            Версия {getHumanIndex(templates, indexTemplateActive)}<br/>
                        </Alert.Heading>
                        <p>
                            Это ☝️ номер актуальной версии, которая используется для приёмок.<br/>Проверить, что именно она используется в мобильном приложении у специалистов, можно нажав там на кнопку <i>Выход</i>.<br/>
                        </p>
                        <h5>История версий:</h5>
                        <ol reversed>
                        {
                            templates.map((template, i) => (
                                <li>
                                    Версия от {template.date_insert.replace('T', ' ')} [#{template.id}] {' '}
                                    { template.is_active==0 ? <Badge bg="secondary">Отмена</Badge>:null}
                                    { template.is_active==1 & i==indexTemplateActive ? <Badge bg="success">Активная</Badge>:null}
                                </li>
                            ))
                        }
                        </ol>

                        <hr />

                        <p>
                            Если после загрузки обновления что-то идет не так, то вы можете вернуть настройки приёмки к предыдущей версии. После возврата к предудыщей версии, повторный обратный переход к текущей активной версии ({getHumanIndex(templates, indexTemplateActive)}) будет невозможен; далее только новая загрузка из таблицы.
                        </p>
                        <div className="">
                            <Form.Check // prettier-ignore
                                type={'checkbox'}
                                label={`Принимаю, что после возврата к версии ${getHumanIndex(templates, indexTemplatePrevious)} обратный переход к ${getHumanIndex(templates, indexTemplateActive)} будет невозможен.`}
                                onChange={(e)=> setIUnderstandReverse(e.target.checked) }
                                className='mb-3'
                            />
                            <Button
                                variant='outline-danger'
                                disabled={!iUnderstandReverse}
                                onClick={()=>reverseTemplate(templates[0].id)}
                            >
                                {
                                    !isLoading ? (
                                        `Вернуться к ${getHumanIndex(templates, indexTemplatePrevious)} версии`
                                    ):(
                                        <Spinner animation="border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </Spinner>
                                    )
                                }
                            </Button>
                        </div>
                    </Alert>
                ):null
            } */}

            

        </>
    )
}

