/**
 * @author Sergey Tumarkin https://tumarkin.me
 */
import {
    Form as BootstrapForm,
    Card, 
    Table, 
    Button,
    ButtonGroup,
    Alert,
    InputGroup,
    Stack,
    Container,
    Row,
    Col,
 } from 'react-bootstrap';
import * as API from '../data/API';
import { useState, useEffect, useCallback, forwardRef } from 'react';
import DatePicker from "react-datepicker";
import { Link, useParams, useNavigate } from 'react-router-dom';
import * as Config from '../data/Config';


import "react-datepicker/dist/react-datepicker.css";

function removeControlCharacters(jsonString) {
    // Use a regular expression to replace control characters with an empty string
    return jsonString.replace(/[\u0000-\u001F]/g, '<br>');
}

export default function Form (props){
    let { formId } = useParams();
    const {authtoken} = props

    

    const [isLoading, setIsLoading] = useState(false);
    const [form, setForm] = useState(null);
    const [dictionary, setDictionary] = useState(null);
    const navigate = useNavigate();
    
    const [showImagesInReport, setShowImagesInReport] = useState(true);
    const [showPdfReport, setShowPdfReport] = useState(true);
    const [showCopyToClipboardReport, setShowCopyToClipboardReport] = useState(false);
    
    useEffect(() => {
        setIsLoading(true)

        API.Get({ method:'getform', authtoken, formId})
        .then(res => {
            const {result, form} = res.data
            if ( result ){
                form.form_json =  form.form 
            
                try {
                    form.form = JSON.parse( form.form )
                } catch (e) {
                    form.form = JSON.parse( removeControlCharacters(form.form) )
                }

                setForm(form)
        
                API.Get({ method:'getdictionary', authtoken, version: form.form.formTemplateVersion})
                .then(res => {
                    const {result, dictionary } = res.data
                    console.log(form.form.formTemplateVersion);
                    console.log(dictionary);
                    if (result && dictionary){
                        console.log(dictionary)
                        setDictionary(dictionary)
                    }
                })

                
            }
        })
        .finally(()=>setIsLoading(false))

    },[])



    const getName = ( obj, showClause = false ) => {
        return obj.name || ( ( dictionary[ (obj.templateId || obj.id) ]?.report || dictionary[ (obj.templateId || obj.id) ]?.name || obj.id )
                              + ( obj.comment ? `; ${obj.comment}` : '' )
                              + ( showClause ? `. ${dictionary[ (obj.templateId ? obj.templateId : obj.id) ].clause}` : '' ) 
                            ) ;
    }

    const getFailChecks = ( form, showClause = false) => {
        let i = 1
        const failChecks = form?.apartment
        .map( (room) => (
        {
            ...room, 
            sections: room.nested.reduce( (sections, section ) => {
                        const checks = section.nested.reduce( (checks, check) => {
                        return checks += (!check.value ? `<br/>${i++}. ${getName(check, showClause)}` : '')
                        }, '' )
                        return sections += (checks!='' ? `<br/>${getName(section).toUpperCase()}:</h2>${checks}<br/>` : '')
                    }, '') +  (room.comment.length>0 ? `Другое:<br/>${i++} ${room.comment}<br/><hr/>` : '' )
        }
        ))
        .reduce( (sum, room) => ( sum += (room.sections!='' ? `<br/><h4>${room.name.toUpperCase()}</h4>${room.sections}` : '') ), '' ) 
      
        return failChecks ? `В результате осмотра квартиры по адресу ${form.address}, ${form.apartmentNum} ` +
                `выявлены следующие недостатки:<br/>`+
                `${failChecks}` : `В результате осмотра квартиры недостатки не выявлены`;
    }


    return (
        <>
            <Button variant="light" onClick={()=>navigate(-1)} className='mb-2'>&nbsp;&nbsp;&larr;&nbsp;&nbsp;</Button>
            <h1>{form?.contractNum || form?.address || 'Без адреса'}{form?.apartmentNum ? ", "+form?.apartmentNum : ""}</h1>
            
            <ol class="breadcrumb mb-4">
                <li class="breadcrumb-item active"></li>
            </ol>
            
            {
                form ? (
                    <>
                        <Row>
                            <Col lg="5">
                                {form.is_active==0 ? <div className='text-danger'>Приёмка отменена</div> : null}
                                <div>Заказчик: {form.customer || 'не указан'}</div>
                                <div>Специалист: {form.fio}</div>
                                <div>Адрес: {form.address || 'не указан'}{form?.apartmentNum ? ", "+form?.apartmentNum : ""}</div>
                                <div>Выявлено {form.failChecksCountTotal} недостатков из {form?.checksCountTotal} проверок</div>
                                <div>Дата проведения: {form.timestamp?.replace('T', ' ')}</div>
                                <div>{formId} / {form.form.token}</div>
                            
                            </Col>
                            <Col lg="4">
                                <Card>
                                <Card.Body>
                                    <Card.Title>Отчеты</Card.Title>
                                    <Card.Subtitle className="mb-4 text-muted">Выберете тип отчета:</Card.Subtitle>
                                    {/* <!-- realease after 30.01.2025 --> 
                                    <Card.Text className='mt-4'>
                                        <BootstrapForm.Check 
                                            type="checkbox"
                                            id="showImagesInReport"
                                            label="C фото"
                                            className='mb-3'
                                            checked={showImagesInReport}
                                            onChange={(e)=> setShowImagesInReport(e.target.checked) }
                                        />
                                        <BootstrapForm.Check
                                            type="checkbox"
                                            id="showPdfReport"
                                            label="PDF"
                                            className='mb-3'
                                            checked={showPdfReport}
                                            onChange={(e)=> setShowPdfReport(e.target.checked) }
                                        />
                                        <BootstrapForm.Check 
                                            type="checkbox"
                                            id="showCopyToClipboardReport"
                                            label="Копирование ячеек"
                                            className='mb-3'
                                            checked={showCopyToClipboardReport}
                                            onChange={(e)=> setShowCopyToClipboardReport(e.target.checked) }
                                        />
                                    </Card.Text>
                                    */}
                                    <ButtonGroup size="md" className="mb-2">
                                        <Button variant="primary" className="px-3" href={`${Config.Domain}/report/${formId}?${(!showImagesInReport?'noimages&':'')}${(showCopyToClipboardReport?'copy&':'')}`} target='_blank'>Акт осмотра</Button>
                                        <Button variant="primary" className="px-3" href={`${Config.Domain}/report/${formId}?${(!showImagesInReport?'noimages&':'')}${(showCopyToClipboardReport?'copy&':'')}expert&token=${form.form.token}`} target='_blank'>✨Заключение</Button>
                                    </ButtonGroup>
                                </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="9">
                                <div className="rounded p-5 mt-4" style={{backgroundColor: '#FAF0DC'}} dangerouslySetInnerHTML={{ __html: form && dictionary ? getFailChecks(form.form) : null }} />
                            </Col>
                        </Row>
                    </>
                ) : null
            }
        </>
    )
}