import { Link } from 'react-router-dom';
import { useState, useEffect, useCallback, forwardRef } from 'react';
import { 
    Button, 
    Form,
    FloatingLabel,
    Stack,
    Container,
    Row,
    Col,
 } from 'react-bootstrap';
import * as API from '../data/API';
import * as Config from '../data/Config';



export default function Login ( props ){
    const [isLoading, setIsLoading] = useState(false);

    const queryParams = new URLSearchParams(window.location.search);
    const recoveryToken = queryParams.get('recovery')
    const onsignup = queryParams.has('onsignup') 
    const webview = queryParams.has('webview')
    const authtoken = queryParams.get('a')


    const [username, setUsername] = useState( queryParams.get('u') || props.username )
    // const [username, setUsername] = useState( queryParams.get('u') )
    const [password, setPassword] = useState( '' )



    const [recoveryPassword, setRecoveryPassword] = useState( '' )
    const [recoveryPasswordRepeat, setRecoveryPasswordRepeat] = useState( '' )
    const [isPasswordStrong, setIsPasswordStrong] = useState( true )
    const [isPasswordEqual, setIsPasswordEqual] = useState( true )

    const [mode, setMode] = useState( recoveryToken ? 'recovery' : 'login' )
    
    const changePassword = () => {
        setIsLoading(true)
        API.Post({ method:'changepassword'}, {password_recovery_hash:recoveryToken, password:recoveryPassword})
        .then(res => {
            if ( res.data.result ){
                alert('Пароль успешно изменен. Вы можете войти с новым паролем')
                setMode('login')
            } else {
                alert('Пароль не изменен. Проверьте актуальность ссылки на восстановление и активность учетной записи.')
            }
        })
        .catch( err => alert(err))
        .finally(()=>setIsLoading(false))
    }
    
    function checkPasswordStength(str) {
        return /^[a-zA-Z0-9!"№#$%:,.;()_+]{6,16}$/.test(str) && /[a-z]/.test(str) && /[A-Z]/.test(str) && /\d/.test(str);
    }

    const CheckOnSignUpAuthToken = ( ) => {
        setIsLoading(true)
        API.Post({ method:'checkonsignupauthtoken'}, {username, authtoken})
        .then(res => {
            console.log( {username, authtoken} )
            console.log( res.data )
            if (res.data.result) {
                onLogin( authtoken, username )
            }
        })
        .finally(()=>setIsLoading(false))
    }

    const onLogin = (val, val2) => props.onLogin(val, val2)
    const RecoveryPasswordRequest = (val) => props.RecoveryPasswordRequest(val)

    const Login = () => {
        if (!username||!password){
            alert("Не указаны e-mail или пароль")
        } else {
            setIsLoading(true)
            API.Post({ method:'login'}, {username, password})
            .then(res => {
                console.log( res.data )
                if ( res.data.result ){
                    onLogin(res.data.authtoken, username)
                } else {
                    alert("Неверный e-mail или пароль")
                }
            })
            .finally(()=>setIsLoading(false))
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            console.log('do validate')
        }
    }

    useEffect(()=>{
        if (onsignup||webview) {
            CheckOnSignUpAuthToken()
        }
        setIsPasswordStrong( recoveryPassword.length==0 || checkPasswordStength(recoveryPassword))
        setIsPasswordEqual( recoveryPassword.length==0 || recoveryPasswordRepeat.length==0 || recoveryPassword==recoveryPasswordRepeat )
    },[recoveryPassword, recoveryPasswordRepeat])

    const LoginForm = () => (
        <form>
            <Stack gap={3}>
                
                <FloatingLabel label="E-mail">
                    <Form.Control 
                        type="email" 
                        placeholder="Email"
                        value={username}
                        onChange={(e)=> setUsername(e.target.value) }
                    />
                </FloatingLabel>
                <FloatingLabel label="Пароль">
                    <Form.Control 
                        type="password" 
                        placeholder="Пароль"
                        value={password}
                        onChange={(e)=> setPassword(e.target.value) }
                        onKeyDown={ (e) => e.key === 'Enter' ? Login() : null }
                    />
                </FloatingLabel>
            </Stack>
            <div className="d-flex align-items-center justify-content-between mt-4 mb-0">
                <Button
                    variant="link"
                    onClick={ ()=>setMode('request') }
                    className='text-decoration-none'
                >
                    Забыли пароль?
                </Button>
                <Button
                    variant="primary"
                    onClick={ Login }
                    disabled={isLoading}
                    className='w-50'
                >
                    Войти
                </Button>
            </div>
        </form>
    )

    const RecoveryRequest = () => (
        <form>
            <Stack gap={3}>
                
                <FloatingLabel label="E-mail">
                    <Form.Control 
                        type="email" 
                        placeholder="Email"
                        value={username}
                        onChange={(e)=> setUsername(e.target.value) }
                    />
                </FloatingLabel>
            </Stack>
            <div className="d-flex align-items-center justify-content-between mt-4 mb-0">
                <Button
                    variant="link"
                    onClick={ ()=>setMode('login') }
                    className='text-secondary text-decoration-none'
                >
                    &larr; Назад
                </Button>

                <Button
                    variant="primary"
                    onClick={()=>RecoveryPasswordRequest(username) }
                    disabled={isLoading}
                    className='w-50'
                >
                    Запрос на восстановление
                </Button>
            </div>
        </form>
    )


    const RecoveryForm = () => (
        <form>
            <Stack gap={3}>
                <div className='h5'>Установите новый пароль:</div>
                <FloatingLabel label="Придумайте пароль">
                    <Form.Control 
                        type="password" 
                        placeholder="Придумайте новый пароль"
                        value={recoveryPassword}
                        onChange={(e)=> setRecoveryPassword(e.target.value) }
                        onKeyDown={ (e) => e.key === 'Enter' ? Login() : null }
                    />
                </FloatingLabel>
                {
                    !isPasswordStrong ? <div className='text-warning'>Пароль слабоват, используйте символы в разном регистре и цифры, не менн 6 символов</div> : null
                }
               
                
                <FloatingLabel label="И введите его повторно">
                    <Form.Control 
                        type="password" 
                        placeholder="Придумайте новый пароль"
                        value={recoveryPasswordRepeat}
                        onChange={(e)=> setRecoveryPasswordRepeat(e.target.value) }
                        onKeyDown={ (e) => e.key === 'Enter' ? Login() : null }
                    />
                </FloatingLabel>
                {
                    !isPasswordEqual ? <div className='text-danger'>Пароли не совпадают</div> : null
                }

            </Stack>
            <div className="d-flex align-items-center justify-content-between mt-4 mb-0">
                <Button
                    variant="link"
                    onClick={ ()=>setMode('login') }
                    className='text-secondary text-decoration-none'
                >
                    Отмена
                </Button>

                <Button
                    variant="primary"
                    onClick={ changePassword }
                    disabled={isLoading}
                    className='w-50'
                >
                    Сохранить новый пароль
                </Button>
            </div>
        </form>
    )

    return (
        <div id="layoutAuthentication">
            <div id="layoutAuthentication_content">
                <main>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-5">
                                <div className="card shadow-lg border-0 rounded-lg mt-5">
                                    <div className="card-header"><h3 className="text-center font-weight-light my-4">Приёмка Про</h3></div>
                                    <div className="card-body">
                                        { onsignup }
                                        
                                       { mode=='recovery' ? RecoveryForm() : null }
                                       { mode=='login' ? LoginForm() : null }
                                       { mode=='request' ? RecoveryRequest() : null }
                                    </div>
                                    <div className="card-footer text-center py-3">
                                        <div className="small"><a href="https://priemka-pro.ru/#signup">Зарегистрировать компанию</a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}