/**
 * @author Sergey Tumarkin https://tumarkin.me
 */

export const Domain = "https://priemka-pro.ru";
export const ApiPath = "/api/admin/";
export const AmplitudeKey = 'f25a5c79e090b04161ab6d54246d390a';

// export const Domain = "https://specnovostroy.app";
// export const ApiPath = "/api/admin/";
// export const AmplitudeKey = '383cad980cbad16bd7a1cc3c86b41543';

// export const Domain = "https://priemka.msk.ru"; 